/* eslint-disable global-require */
import axios from 'axios';
import Avatar from 'react-avatar';
import React, { useEffect, useState, useContext } from 'react';
import { Col, Row, Image, Container } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { SpinnerDotted } from 'spinners-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/fontawesome-free-solid';
import { getCookies } from '../../hooks/useCookies';
import { TableContext } from '../../contexts/tableContext';
import styles from './viewUser.module.scss';
import { DateFormatter } from '../../utilityFunctions/utilsFunctions';
import envValues from '../../enviornment';

function ViewUsers() {
  const { dashboardStyle } = useContext(TableContext);
  const { setDashboardHeader } = useContext(TableContext);
  const { bodyStyle } = useContext(TableContext);
  const { formthemeStyle } = useContext(TableContext);
  const { viewformStyle } = useContext(TableContext);
  const params = useParams();
  let userId;
  const token = `Bearer ${getCookies('Token')}`;
  const [loading, setLoading] = useState(false);
  const [details, setDetails] = useState([]);
  const dateFormat = getCookies('dateFormat');
  let statusCode;
  const getUserDetails = async () => {
    setLoading(true);
    axios
      .get(`${envValues.REACT_APP_API_ENDPOINT}/user/${userId}`, {
        method: 'GET',
        headers: { Authorization: token },
      })
      .then((resp) => {
        statusCode = resp.status;
        return resp.data;
      })
      .then((res) => {
        if (statusCode === 200) {
          setDetails(res.data.user);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        return err;
      });
  };
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
    userId = params.userId;
    getUserDetails();
    setDashboardHeader('User Details');
  }, []);
  return (
    <div className={styles[bodyStyle]}>
      <div
        className={styles[dashboardStyle]}
        style={{ minHeight: '100vh' }}
        id={styles.dashboardcont2}
      >
        <div className={styles[formthemeStyle]} id={styles.addform}>
          {loading ? (
            <SpinnerDotted
              style={{
                color: '#39979d',
                left: '50%',
                position: 'relative',
                textAlign: 'center',
                top: '50%',
              }}
            />
          ) : (
            <Container fluid>
              <FontAwesomeIcon
                icon={faArrowLeft}
                value="Back"
                onClick={() => {
                  navigate(-1);
                }}
                className={styles.arrowback}
              />
              <Row>
                <Col lg={12}>
                  <div className={styles.profilepicdiv}>
                    {details.image ? (
                      <Image src={details.image} alt={details.name} className={styles.profilepic} />
                    ) : (
                      <Avatar name={details.name} round />
                    )}
                  </div>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col lg={12} md={12} sm={12}>
                  <div className={styles.detaildiv}>
                    <Row>
                      <Col lg={4}>
                        <div className={styles[viewformStyle]} id={styles.empdiv}>
                          <span className={styles.title}>Name</span>
                          <p className={styles.empname}>
                            {details.name === undefined || '' ? 'N/A' : details?.name}
                          </p>
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className={styles[viewformStyle]} id={styles.empdiv}>
                          <span className={styles.title}>Email</span>
                          <p className={styles.empname}>{details.email}</p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={8}>
                        <div className={styles[viewformStyle]} id={styles.empdiv}>
                          <span className={styles.title}>Bio</span>
                          <p className={styles.empname}>
                            {details.bio === undefined || '' ? 'N/A' : details?.bio}
                          </p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={4}>
                        <div className={styles[viewformStyle]} id={styles.empdiv}>
                          <span className={styles.title}>Subscription</span>
                          <p className={styles.empname}>
                            {details.subscription === undefined || ''
                              ? 'N/A'
                              : details?.subscription}
                          </p>
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className={styles[viewformStyle]} id={styles.empdiv}>
                          <span className={styles.title}>Signed Up</span>
                          <p className={styles.empname}>
                            {details.createdAt
                              ? DateFormatter(details.createdAt, dateFormat || 'toDateTime')
                              : 'N/A'}
                          </p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={4}>
                        <div className={styles[viewformStyle]} id={styles.empdiv}>
                          <span className={styles.title}>Status</span>
                          <p className={styles.empname}>{details.active ? 'Active' : 'InActive'}</p>
                        </div>
                      </Col>
                      <Col lg={4}>
                        <div className={styles[viewformStyle]} id={styles.empdiv}>
                          <span className={styles.title}>Block Status</span>
                          <p className={styles.empname}>
                            {details.isBlocked ? 'Blocked' : 'Accessible'}
                          </p>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      {details?.location ? (
                        <Col lg={4}>
                          <div className={styles[viewformStyle]} id={styles.empdiv}>
                            <span className={styles.title}>Location</span>
                            <p className={styles.empname}>
                              {details.location === undefined || details.location === ''
                                ? 'N/A'
                                : details.location}
                            </p>
                          </div>
                        </Col>
                      ) : (
                        ''
                      )}
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          )}
        </div>
      </div>
    </div>
  );
}

export default ViewUsers;
